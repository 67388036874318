import axios from 'axios';
const baseApi = process.env.BASEAPI || "https://api.idastormng.com";

const axiosInstance = axios.create({
    baseURL: baseApi,
    withCredentials: true,
});

// axiosInstance.interceptors.response.use(
//     response => response,
//     error => {
//         if (error.response.status === 403 && error.response.data.message === 'Account is inactive') {
//             // Handle inactive account logic, e.g., redirect to login or show a message
//             window.location.href = '/login';
//         }
//         return Promise.reject(error);
//     }
// );

export default axiosInstance;
