import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Typography } from '@material-ui/core';
import axiosInstance from './axiosInstance'; // Import the configured axios instance


const RoadmapTimeline = () => {
    const [roadmapItems, setRoadmapItems] = useState([]);

    useEffect(() => {
        axiosInstance.get(`/api/roadmap`)
            .then(response => {
                setRoadmapItems(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);
    

    return (
        <VerticalTimeline>
            {roadmapItems.map((item, index) => (
                <VerticalTimelineElement
                    key={index}
                    date={new Date(item.date).toLocaleDateString()}
                    iconStyle={{ background: item.status === 'completed' ? 'rgb(16, 204, 82)' : item.status === 'in-progress' ? 'rgb(33, 150, 243)' : 'rgb(233, 30, 99)', color: '#fff' }}
                >
                    <Typography variant="h6">{item.title}</Typography>
                    <Typography>{item.description}</Typography>
                    <Typography>Status: {item.status}</Typography>
                </VerticalTimelineElement>
            ))}
        </VerticalTimeline>
    );
};

export default RoadmapTimeline;
