import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Typography } from '@material-ui/core';
import axiosInstance from './axiosInstance'; // Import the configured axios instance


const ProjectUpdatesTimeline = () => {
    const [updates, setUpdates] = useState([]);

    useEffect(() => {
        axiosInstance.get(`/api/project-updates`)
            .then(response => {
                setUpdates(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);
    

    return (
        <VerticalTimeline>
            {updates.map((update, index) => (
                <VerticalTimelineElement
                    key={index}
                    date={new Date(update.date).toLocaleDateString()}
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                >
                    <Typography variant="h6">{update.title}</Typography>
                    <Typography>{update.description}</Typography>
                    <Typography>{new Date(update.date).toLocaleDateString()}</Typography>
                </VerticalTimelineElement>
            ))}
        </VerticalTimeline>
    );
};

export default ProjectUpdatesTimeline;
