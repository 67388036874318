import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent, CardActions, Button, CircularProgress, Modal, TextField, Checkbox, FormControlLabel, Grid, Box } from '@material-ui/core';
import axiosInstance from './axiosInstance'; // Use the configured axios instance
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3),
    },
    card: {
        marginBottom: theme.spacing(2),
        position: 'relative',
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#f49336',
    },
    modal: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        borderRadius: '15px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '60%',
        maxHeight: '80%',
        overflowY: 'auto',
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    detailsBox: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(2),
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
    },
    smallButton: {
        padding: theme.spacing(1),
        fontSize: '0.875rem',
    },
    statusIndicator: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        position: 'absolute',
        top: 10,
        right: 10,
    },
    active: {
        backgroundColor: 'green',
    },
    inactive: {
        backgroundColor: 'red',
    },
}));

const UserManagement = () => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [roles, setRoles] = useState([]);
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axiosInstance.get('/api/users');
            setUsers(response.data || []); // Ensure users is always an array
        } catch (error) {
            console.error('Error fetching users:', error);
            setUsers([]); // Ensure users is an empty array on error
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModal = (user) => {
        setSelectedUser(user);
        setRoles(user.roles);
        setIsActive(user.accountStatus === 'active');
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedUser(null);
        setModalOpen(false);
    };

    const handleSaveUser = async () => {
        try {
            const response = await axiosInstance.put(`/api/users/${selectedUser._id}`, {
                email: selectedUser.email,
                roles: roles,
                accountStatus: isActive ? 'active' : 'inactive'
            });
            setUsers(users.map(user => user._id === selectedUser._id ? response.data : user));
            handleCloseModal();
        } catch (error) {
            console.error('Error saving user:', error);
        }
    };

    const handleRoleChange = (event) => {
        if (event.target.checked) {
            setRoles([...roles, event.target.name]);
        } else {
            setRoles(roles.filter(role => role !== event.target.name));
        }
    };

    const handleIsActiveChange = (event) => {
        setIsActive(event.target.checked);
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Container className={classes.container}>
            <Typography variant="h4" gutterBottom>
                User Management
            </Typography>
            {users.length > 0 ? (
                users.map((user) => (
                    <Card key={user._id} className={classes.card}>
                        <div className={`${classes.statusIndicator} ${user.accountStatus === 'active' ? classes.active : classes.inactive}`} />
                        <CardContent>
                            <Typography variant="h6">
                                {user.email}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button className={`${classes.button} ${classes.smallButton}`} onClick={() => handleOpenModal(user)} variant="contained" color="primary">
                                View / Edit
                            </Button>
                        </CardActions>
                    </Card>
                ))
            ) : (
                <Typography>No users available</Typography>
            )}

            {selectedUser && (
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    className={classes.modal}
                >
                    <div className={classes.paper}>
                        <Typography variant="h6">
                            Edit User
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textField}
                                    value={selectedUser.email}
                                    onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                                />
                                <Typography variant="h6">
                                    Roles
                                </Typography>
                                <FormControlLabel
                                    control={<Checkbox checked={roles.includes('admin')} onChange={handleRoleChange} name="admin" />}
                                    label="Admin"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={roles.includes('user')} onChange={handleRoleChange} name="user" />}
                                    label="User"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={isActive} onChange={handleIsActiveChange} />}
                                    label="Active"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box className={classes.detailsBox}>
                                    <Typography variant="body1"><strong>Updated At:</strong> {new Date(selectedUser.updatedAt).toLocaleString()}</Typography>
                                    <Typography variant="body1"><strong>Google ID:</strong> {selectedUser.googleId || 'N/A'}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: '20px' }}>
                            <Grid item xs={6}>
                                <Button variant="contained" color="primary" onClick={handleSaveUser} fullWidth>
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" onClick={handleCloseModal} fullWidth>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
            )}
        </Container>
    );
};

export default UserManagement;
