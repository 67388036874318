import React, { useEffect, useState } from 'react';
import { Container, Table, TableHead, TableRow, TableCell, TableBody, Typography, Box, IconButton } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import axiosInstance from './axiosInstance'; // Import the configured axios instance
import { makeStyles } from '@material-ui/core/styles';
import EditReleaseModal from './EditReleaseModal';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

const calculateCountdown = (date) => {
    const now = new Date();
    const releaseDate = new Date(date);
    const timeDifference = releaseDate - now;
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
};

const calculateProgress = (date) => {
    const now = new Date();
    const releaseDate = new Date(date);
    const startDate = new Date(releaseDate);
    startDate.setMonth(startDate.getMonth() - 6);
    const totalDuration = releaseDate - startDate;
    const elapsedDuration = now - startDate;
    return Math.min(100, Math.max(0, (elapsedDuration / totalDuration) * 100));
};

const CustomLinearProgress = ({ value }) => (
    <Box style={{ height: '10px', borderRadius: '5px', background: 'linear-gradient(to right, #0000FF, #FFFF00, #FF0000)' }}>
        <Box style={{ width: `${value}%`, height: '100%', borderRadius: '5px', backgroundColor: '#200a57' }}></Box>
    </Box>
);

const UpcomingReleases = ({ progressType, searchQuery }) => {
    const classes = useStyles();
    const [releases, setReleases] = useState([]);
    const [selectedRelease, setSelectedRelease] = useState(null);

    useEffect(() => {
        axiosInstance.get(`/api/releases`)
            .then(response => {
                const upcoming = response.data.filter(release => new Date(release.releaseDate) > new Date());
                setReleases(upcoming);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);
    
    const handleEditClick = (release) => {
        setSelectedRelease(release);
    };

    const handleUpdate = (updatedRelease) => {
        setReleases(releases.map(r => (r._id === updatedRelease._id ? updatedRelease : r)));
    };

    const handleClose = () => {
        setSelectedRelease(null);
    };

    const handlePayClick = (release) => {
        const payboxLink = `https://paybox.example.com/payment?releaseId=${release._id}`; // Replace with the actual PayBox link format
        window.open(payboxLink, '_blank');
    };
    
    const handleDeleteRelease = async (releaseId) => {
        try {
            await axiosInstance.delete(`/api/releases/${releaseId}`);
            setReleases(releases.filter(release => release._id !== releaseId));
        } catch (error) {
            console.error('There was an error deleting the release!', error);
        }
    };
    

    return (
        <Container style={{ marginTop: '20px' }}>
            <Typography variant="h4" align="center" gutterBottom>Upcoming Releases</Typography>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Release Date</TableCell>
                        <TableCell>Countdown (Days)</TableCell>
                        <TableCell>Progress</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {releases.filter(release => release.name.toLowerCase().includes(searchQuery.toLowerCase())).map((release, index) => (
                        <TableRow key={index}>
                            <TableCell>{release.name}</TableCell>
                            <TableCell>{new Date(release.releaseDate).toLocaleDateString()}</TableCell>
                            <TableCell>{calculateCountdown(release.releaseDate)}</TableCell>
                            <TableCell>
                                {progressType === 'linear' ? (
                                    <CustomLinearProgress value={calculateProgress(release.releaseDate)} />
                                ) : (
                                    <Box style={{ width: 60, height: 60 }}>
                                        <CircularProgressbar
                                            value={calculateProgress(release.releaseDate)}
                                            text={`${Math.round(calculateProgress(release.releaseDate))}%`}
                                            styles={buildStyles({
                                                textColor: '#000',
                                                pathColor: `rgba(62, 152, 199, ${calculateProgress(release.releaseDate) / 100})`,
                                                trailColor: '#d6d6d6',
                                                strokeLinecap: 'butt',
                                                pathTransitionDuration: 0.5,
                                                pathTransition: 'none',
                                            })}
                                        />
                                    </Box>
                                )}
                            </TableCell>
                            <TableCell>

                                <IconButton onClick={() => handleEditClick(release)}>
                                    <EditIcon />
                                </IconButton>
                                
                                <IconButton onClick={() => handlePayClick(release)}>
                                    <AttachMoneyIcon />
                                </IconButton>
                                
                                <IconButton onClick={() => handleDeleteRelease(release._id)}>
                                    <DeleteIcon />
                                </IconButton>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {selectedRelease && (
                <EditReleaseModal
                    open={Boolean(selectedRelease)}
                    handleClose={handleClose}
                    release={selectedRelease}
                    onUpdate={handleUpdate}
                />
            )}
        </Container>
    );
};

export default UpcomingReleases;
