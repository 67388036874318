import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent, CardActions, Button, CircularProgress } from '@material-ui/core';
import axiosInstance from './axiosInstance'; // Use the configured axios instance
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3),
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#f49336',
    },
}));

const RouteManager = () => {
    const classes = useStyles();
    const [routes, setRoutes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchRoutes();
    }, []);

    const fetchRoutes = async () => {
        try {
            const response = await axiosInstance.get('/api/microservices');
            setRoutes(response.data.microservices || []);
        } catch (error) {
            console.error('Error fetching routes:', error);
            setRoutes([]); // Ensure routes is an empty array on error
        } finally {
            setLoading(false);
        }
    };

    const handleLoadRoute = async (routeName) => {
        try {
            await axiosInstance.post(`/api/microservices/load/${routeName}`);
            fetchRoutes();
        } catch (error) {
            console.error(`Error loading route ${routeName}:`, error);
        }
    };

    const handleUnloadRoute = async (routeName) => {
        try {
            await axiosInstance.post(`/api/microservices/unload/${routeName}`);
            fetchRoutes();
        } catch (error) {
            console.error(`Error unloading route ${routeName}:`, error);
        }
    };

    const handleReloadRoute = async (routeName) => {
        try {
            await axiosInstance.post(`/api/microservices/reload/${routeName}`);
            fetchRoutes();
        } catch (error) {
            console.error(`Error reloading route ${routeName}:`, error);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Container className={classes.container}>
            <Typography variant="h4" gutterBottom>
                Route Manager
            </Typography>
            {routes.length > 0 ? (
                routes.map((route) => (
                    <Card key={route.name} className={classes.card}>
                        <CardContent>
                            <Typography variant="h6">
                                {route.name} - {route.status}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button className={classes.button} onClick={() => handleLoadRoute(route.name)} variant="contained" color="primary">
                                Load
                            </Button>
                            <Button className={classes.button} onClick={() => handleUnloadRoute(route.name)} variant="contained" color="secondary">
                                Unload
                            </Button>
                            <Button className={classes.button} onClick={() => handleReloadRoute(route.name)} variant="contained" color="default">
                                Reload
                            </Button>
                        </CardActions>
                    </Card>
                ))
            ) : (
                <Typography>No routes available</Typography>
            )}
        </Container>
    );
};

export default RouteManager;
