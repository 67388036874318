import React, { useEffect, useState } from 'react';
import { Container, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import axiosInstance from './axiosInstance'; // Import the configured axios instance



const ReleasedItems = ({ searchQuery }) => {
    const [releases, setReleases] = useState([]);

    useEffect(() => {
        axiosInstance.get(`/api/releases`)
            .then(response => {
                const released = response.data.filter(release => new Date(release.releaseDate) <= new Date());
                setReleases(released);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);
    
    const filteredReleases = releases.filter(release => release.name.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <Container>
            <Typography variant="h4" align="center" gutterBottom>Released Items</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Release Date</TableCell>
                        <TableCell>Days Since Release</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredReleases.map((release, index) => (
                        <TableRow key={index}>
                            <TableCell>{release.name}</TableCell>
                            <TableCell>{new Date(release.releaseDate).toLocaleDateString()}</TableCell>
                            <TableCell>{Math.ceil((new Date() - new Date(release.releaseDate)) / (1000 * 60 * 60 * 24))}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
};

export default ReleasedItems;
