import React, { useState } from 'react';
import { Container, Typography, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axiosInstance from './axiosInstance'; // Import the configured axios instance

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        color: '#200a57',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '400px',
        width: '100%',
        color: '#200a57',
    },
    input: {
        marginBottom: theme.spacing(2),
        width: '100%',
        '& .MuiInputBase-input': {
            color: '#200a57',
        },
        '& .MuiFormLabel-root': {
            color: '#200a57',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#200a57',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#200a57',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#200a57',
        },
    },
    button: {
        marginTop: theme.spacing(2),
        color: 'black',
        borderColor: '#200a57',
    },
    message: {
        color: 'black',
    },
}));

const Register = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [googleId, setGoogleId] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [message, setMessage] = useState('');

    const handleRegister = async () => {
        try {
            const response = await axiosInstance.post(`/api/auth/register`, { email, password, googleId, isAdmin });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.message || 'Error registering user');
        }
    };

    return (
        <Container className={classes.container}>
            <Typography variant="h4" gutterBottom>
                Register User
            </Typography>
            <form className={classes.form}>
                <TextField
                    label="Email"
                    variant="outlined"
                    className={classes.input}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    className={classes.input}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    label="Google ID"
                    variant="outlined"
                    className={classes.input}
                    value={googleId}
                    onChange={(e) => setGoogleId(e.target.value)}
                />
                <FormControlLabel
                    control={<Checkbox checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} />}
                    label="Admin Permissions"
                />
                <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={handleRegister}
                >
                    Register
                </Button>
            </form>
            {message && <Typography variant="body1" className={classes.message}>{message}</Typography>}
        </Container>
    );
};

export default Register;
