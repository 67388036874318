import React, { useState } from 'react';
import { Container, Typography, TextField, Button, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axiosInstance from './axiosInstance'; // Use the configured axios instance
import CryptoJS from 'crypto-js';

const encryptionKey = process.env.ENCRYPTION_KEY || "Super_Secret_Encryption_Key";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        color: 'white',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '400px',
        width: '100%',
        color: 'white',
    },
    input: {
        marginBottom: theme.spacing(2),
        width: '100%',
        '& .MuiInputBase-input': {
            color: 'white',
        },
        '& .MuiFormLabel-root': {
            color: 'white',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
    },
    button: {
        marginTop: theme.spacing(2),
        color: 'white',
        borderColor: 'white',
    },
    message: {
        color: 'white',
    },
}));

const Auth = ({ onAuthenticate }) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);


    const handleLogin = async () => {
        setLoading(true);
        try {
            const encryptedPassword = CryptoJS.AES.encrypt(password, encryptionKey).toString();
            const response = await axiosInstance.post('/api/auth/login', { email, password: encryptedPassword });
            onAuthenticate(true, response.data.user);
        } catch (error) {
            setMessage(error.response?.data?.message || 'Error logging in');
        } finally {
        setLoading(false);
        }
    };

    const handleGoogleLogin = () => {
        window.location.href = `/auth/google`;
    };

    return (
        <Container className={classes.container}>
            <Typography variant="h4" gutterBottom>
                Login
            </Typography>
            <form className={classes.form}>
                <TextField
                    label="Email"
                    variant="outlined"
                    className={classes.input}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    className={classes.input}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={handleLogin}
                >
                    {loading ? <CircularProgress size={24} /> : 'Login'}
                </Button>
                <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={handleGoogleLogin}
                >
                    Login with Google
                </Button>
            </form>
            {message && <Typography variant="body1" className={classes.message}>{message}</Typography>}
        </Container>
    );
};

export default Auth;
