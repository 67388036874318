import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Box } from '@material-ui/core';
import { Copyright as CopyrightIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: 'transparent',
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: "gray"
  },
  icon: {
    marginRight: theme.spacing(1),
    color: "gray"
  },
}));

const Footer = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      <Container className={classes.content}>
        <Box display="flex" alignItems="center">
          <CopyrightIcon className={classes.icon} />
          <Typography variant="body2" color="gray">
            Itamar P {currentYear}
          </Typography>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
