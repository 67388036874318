import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Typography } from '@material-ui/core';
import axiosInstance from './axiosInstance'; // Import the configured axios instance


const UpcomingReleasesHorizontalTimeline = () => {
    const [releases, setReleases] = useState([]);

    useEffect(() => {
        axiosInstance.get(`/api/releases`)
            .then(response => {
                setReleases(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);

    
        return (
        <VerticalTimeline>
            {releases.map((release, index) => (
                <VerticalTimelineElement
                    key={index}
                    date={new Date(release.releaseDate).toLocaleDateString()}
                    iconStyle={{ background: 'rgb(150, 177, 123)', color: '#fff' }}
                >
                    <Typography variant="h6">{release.name}</Typography>
                    <Typography>{release.name}</Typography>
                </VerticalTimelineElement>
            ))}
        </VerticalTimeline>
    );
};

export default UpcomingReleasesHorizontalTimeline;
