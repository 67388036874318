import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { createTheme , ThemeProvider } from '@material-ui/core/styles';
import UpcomingReleases from './components/UpcomingReleases';
import ReleasedItems from './components/ReleasedItems';
import AdminPanel from './components/AdminPanel';
import Auth from './components/Auth';
import Register from './components/Register';
import UpcomingReleasesHorizontalTimeline from './components/UpcomingReleasesHorizontalTimeline';
import ProjectUpdatesTimeline from './components/ProjectUpdatesTimeline';
import RoadmapTimeline from './components/RoadmapTimeline';
import { AppBar, Toolbar, Typography, Tabs, Tab, Container, Box, Fab, Drawer, FormControl, InputLabel, Select, MenuItem, InputBase, Button } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import EventNoteIcon from '@material-ui/icons/EventNote';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TimelineIcon from '@material-ui/icons/Timeline';
import axiosInstance from './components/axiosInstance.js'; // Import the configured axios instance
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import Loading from './components/Loading.js'; // Import Loading component
import RouteManager from './components/RouteManager.js'; // Import Loading component
import UserManagement from './components/UserManagement';


console.log(process.env.NODE_ENV)
if ((process.env.NODE_ENV).trim() === 'production'){
    disableReactDevTools();
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderRadius: theme.shape.borderRadius * 2,
        maxWidth: '60%',
        margin: '20px auto',
        backgroundColor: 'transparent',
        boxShadow: 'none', 
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius * 4,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    tabsContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px auto',
    },
    tabs: {
        backgroundColor: '#f49336',
        borderRadius: theme.shape.borderRadius * 2,
        padding: '10px',
    },
    tab: {
        minWidth: '150px',
        borderRadius: theme.shape.borderRadius * 2,
        color: '#ffffff',
    },
    content: {
        marginTop: theme.spacing(3),
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: theme.shape.borderRadius * 2,
        padding: theme.spacing(3),
    },
    background: {
        backgroundImage: 'url(/background.webp)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: -1,
        filter: 'blur(2px) brightness(0.5)',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'center',
    },
    toolbarTitle: {
        flexGrow: 1,
        textAlign: 'center',
        color: '#ffffff',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        backgroundColor: '#f49336',
    },
    drawer: {
        width: 250,
        padding: '20px',
    },
    logoutButton: {
        color: '#ffffff',
    },
    MenuItem: {
        display: 'block',
        width: '100%',
    }
}));

const App = () => {
    const classes = useStyles();
    // const [tabValue, setTabValue] = useState(0);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [progressType, setProgressType] = useState('linear');
    const [searchQuery, setSearchQuery] = useState('');
    const [themeType, setThemeType] = useState('light');
    const [loading, setLoading] = useState(true);

    const theme = createTheme({
        palette: {
            type: themeType,
        },
    });

    useEffect(() => {
        axiosInstance.get(`/api/auth/check`)
            .then(response => {
                if (response.data.authenticated) {
                    setIsAuthenticated(true);
                    setUser(response.data.user);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error checking authentication!', error);
                setLoading(false);
            });
    }, []);
    

    // const handleChange = (event, newValue) => {
    //     setTabValue(newValue);
    // };

    const handleAuthentication = (status, userData) => {
    setIsAuthenticated(status);
    setUser(userData);
    if (status) {
        window.location.href = '/upcomingReleases';
    }
};

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleProgressTypeChange = (event) => {
        setProgressType(event.target.value);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleThemeChange = (event) => {
        setThemeType(event.target.value);
    };

    const handleLogout = async () => {
        try {
            setLoading(true);
            await axiosInstance.get(`/api/auth/logout`);
            setIsAuthenticated(false);
            setUser(null);
            setLoading(false);
        } catch (error) {
            console.error('There was an error logging out!', error);
            setLoading(false);
        }
    };
    
    // if (loading) {
        // return <Loading loading={true} />;
    //}

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <div className={classes.background}>
                    <Box className={classes.overlay} />
                </div>
                {loading && <Loading loading={loading} />} {/* Add Loading component */}
                {isAuthenticated ? (
                    <>
                        <AppBar position="static" className={classes.appBar}>
                            <Toolbar className={classes.toolbar}>
                                <Typography variant="h6" className={classes.toolbarTitle}>
                                    Release Countdown
                                </Typography>
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder="Search…"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                                <Button className={classes.logoutButton} onClick={handleLogout}>
                                    <ExitToAppIcon />
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <Box className={classes.tabsContainer}>
                            <Tabs value={false} aria-label="simple tabs example" className={classes.tabs}>
                                <Tab className={classes.tab} icon={<EventNoteIcon />} label="Upcoming Releases" component={NavLink} to="/upcomingReleases" />
                                <Tab className={classes.tab} icon={<CheckCircleIcon />} label="Released Items" component={NavLink} to="/ReleasedItems" />
                                <Tab className={classes.tab} icon={<TimelineIcon />} label="Releases Timeline" component={NavLink} to="/ReleasesTimeline" />
                                <Tab className={classes.tab} icon={<TimelineIcon />} label="Project Updates" component={NavLink} to="/ProjectUpdates" />
                                <Tab className={classes.tab} icon={<TimelineIcon />} label="Roadmap" component={NavLink} to="/RoadMap" />
                                {user && user.roles.includes('admin') && (
                                    <Tab className={classes.tab} icon={<SettingsIcon />} label="Admin Panel" component={NavLink} to="/AdminPanel" />
                                )}
                                {user && user.roles.includes('admin') && (
                                    <Tab className={classes.tab} icon={<SettingsIcon />} label="Register" component={NavLink} to="/Register" />
                                )}
                                {user && user.roles.includes('admin') && (
                                    <Tab className={classes.tab} icon={<SettingsIcon />} label="User Management" component={NavLink} to="/UserManagement" />
                                )}
                                {user && user.roles.includes('admin') && (
                                    <Tab className={classes.tab} icon={<SettingsIcon />} label="Route Manager" component={NavLink} to="/routeManager" />
                                )}
                                
                            </Tabs>
                        </Box>
                        <Container className={classes.content}>
                            <Routes>
                                <Route path="/upcomingReleases" element={<UpcomingReleases progressType={progressType} searchQuery={searchQuery} />} />
                                <Route path="/ReleasedItems" element={<ReleasedItems searchQuery={searchQuery} />} />
                                <Route path="/ReleasesTimeline" element={<UpcomingReleasesHorizontalTimeline />} />
                                <Route path="/ProjectUpdates" element={<ProjectUpdatesTimeline />} />
                                <Route path="/RoadMap" element={<RoadmapTimeline />} />
                                <Route path="/AdminPanel" element={user && user.roles.includes('admin') ? <AdminPanel /> : <Navigate to="/" />} />
                                <Route path="/Register" element={user && user.roles.includes('admin') ? <Register /> : <Navigate to="/" />} />
                                <Route path="/UserManagement" element={user && user.roles.includes('admin') ? <UserManagement /> : <Navigate to="/" />} />
                                <Route path="/routeManager" element={user && user.roles.includes('admin') ? <RouteManager /> : <Navigate to="/" />} />
                                <Route path="/login" element={<Auth onAuthenticate={handleAuthentication} />} />

                            </Routes>
                        </Container>
                        <Fab className={classes.fab} onClick={toggleDrawer(true)}>
                            <SettingsIcon />
                        </Fab>
                        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                            <Box
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                                className={classes.drawer}
                            >
                                <Typography variant="h6" gutterBottom>Customize Progress Bar</Typography>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Progress Bar Type</InputLabel>
                                    <Select
                                        value={progressType}
                                        onChange={handleProgressTypeChange}
                                        label="Progress Bar Type"
                                    >
                                        <MenuItem className={classes.menuItem} value="linear">Linear</MenuItem>
                                        <MenuItem className={classes.menuItem} value="pie">Pie</MenuItem>
                                    </Select>
                                </FormControl>
                                <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>Select Theme</Typography>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Theme</InputLabel>
                                    <Select
                                        value={themeType}
                                        onChange={handleThemeChange}
                                        label="Theme"
                                    >
                                        <MenuItem className={classes.menuItem} value="light">Light</MenuItem>
                                        <MenuItem className={classes.menuItem} value="dark">Dark</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Drawer>
                    </>
                ) : (
                    !loading && <Auth onAuthenticate={handleAuthentication} />
                )}
                )
            </Router>
        </ThemeProvider>
    );
};

export default App;
