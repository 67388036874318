import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';
import axiosInstance from './axiosInstance'; // Import the configured axios instance


const EditReleaseModal = ({ open, handleClose, release, onUpdate }) => {
    const [name, setName] = useState(release.name);
    const [releaseDate, setReleaseDate] = useState(new Date(release.releaseDate).toISOString().substring(0, 10));
    const handleUpdate = async () => {
        try {
            const updatedRelease = { ...release, name, releaseDate };
            await axiosInstance.put(`/api/releases/${release._id}`, updatedRelease);
            onUpdate(updatedRelease);
            handleClose();
        } catch (error) {
            console.error('Error updating release:', error);
        }
    };
    

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Release</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    label="Release Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={releaseDate}
                    onChange={(e) => setReleaseDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Cancel</Button>
                <Button onClick={handleUpdate} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditReleaseModal;
