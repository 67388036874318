import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CSSTransition } from 'react-transition-group';
import './Loading.css'; // Import CSS for animations

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Very low opacity
        zIndex: 1301, // Higher than other elements
    },
    loadingContent: {
        textAlign: 'center',
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Very low opacity
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
    },
    logo: {
        marginBottom: theme.spacing(2),
    },
    loadingText: {
        marginTop: theme.spacing(1),
    },
}));

const Loading = ({ loading }) => {
    const classes = useStyles();
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        let timeout;
        if (loading) {
            setShowLoading(true);
        } else {
            timeout = setTimeout(() => {
                setShowLoading(false);
            }, 500); // Minimum loading time in milliseconds
        }
        return () => clearTimeout(timeout);
    }, [loading]);

    return (
        <CSSTransition
            in={showLoading}
            timeout={700}
            classNames="loading"
            unmountOnExit
        >
            <div className={classes.loadingContainer}>
                <div className={classes.loadingContent}>
                    <img src="/favicon.ico" alt="Logo" className={classes.logo} /> {/* Replace with your logo */}
                    <div className={classes.loadingText}>
                        <img src="/Loading.png" alt="Loading..." />
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Loading;
