import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axiosInstance from './axiosInstance'; // Import the configured axios instance

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: theme.spacing(3),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '400px',
        width: '100%',
        padding: theme.spacing(2),
    },
    input: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
    },
    tabs: {
        marginBottom: theme.spacing(2),
    },
}));

const AdminPanel = () => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const [name, setName] = useState('');
    const [releaseDate, setReleaseDate] = useState('');
    const [projectTitle, setProjectTitle] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [projectDate, setProjectDate] = useState('');
    const [roadmapTitle, setRoadmapTitle] = useState('');
    const [roadmapDescription, setRoadmapDescription] = useState('');
    const [roadmapStatus, setRoadmapStatus] = useState('planned');
    const [roadmapDate, setRoadmapDate] = useState('');
    const [message, setMessage] = useState('');

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleAddRelease = async () => {
        try {
            const response = await axiosInstance.post(`/api/releases`, { name, releaseDate });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.message || 'Error adding release');
        }
    };

    const handleAddProjectUpdate = async () => {
        try {
            const response = await axiosInstance.post(`/api/project-updates`, { title: projectTitle, description: projectDescription, date: projectDate });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.message || 'Error adding project update');
        }
    };

    const handleAddRoadmapItem = async () => {
        try {
            const response = await axiosInstance.post(`/api/roadmap`, { title: roadmapTitle, description: roadmapDescription, status: roadmapStatus, date: roadmapDate });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.message || 'Error adding roadmap item');
        }
    };
    

    return (
        <Container className={classes.container}>
            <Typography variant="h4" gutterBottom>
                Admin Panel
            </Typography>
            <Tabs value={activeTab} onChange={handleTabChange} className={classes.tabs} centered>
                <Tab label="Add Release" />
                <Tab label="Add Project Update" />
                <Tab label="Add Roadmap Item" />
            </Tabs>
            {activeTab === 0 && (
                <form className={classes.form}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        className={classes.input}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        label="Release Date"
                        type="date"
                        variant="outlined"
                        className={classes.input}
                        value={releaseDate}
                        onChange={(e) => setReleaseDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleAddRelease}
                    >
                        Add Release
                    </Button>
                </form>
            )}
            {activeTab === 1 && (
                <form className={classes.form}>
                    <TextField
                        label="Title"
                        variant="outlined"
                        className={classes.input}
                        value={projectTitle}
                        onChange={(e) => setProjectTitle(e.target.value)}
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        className={classes.input}
                        value={projectDescription}
                        onChange={(e) => setProjectDescription(e.target.value)}
                    />
                    <TextField
                        label="Date"
                        type="date"
                        variant="outlined"
                        className={classes.input}
                        value={projectDate}
                        onChange={(e) => setProjectDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleAddProjectUpdate}
                    >
                        Add Project Update
                    </Button>
                </form>
            )}
            {activeTab === 2 && (
                <form className={classes.form}>
                    <TextField
                        label="Title"
                        variant="outlined"
                        className={classes.input}
                        value={roadmapTitle}
                        onChange={(e) => setRoadmapTitle(e.target.value)}
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        className={classes.input}
                        value={roadmapDescription}
                        onChange={(e) => setRoadmapDescription(e.target.value)}
                    />
                    <TextField
                        label="Status"
                        variant="outlined"
                        className={classes.input}
                        value={roadmapStatus}
                        onChange={(e) => setRoadmapStatus(e.target.value)}
                    />
                    <TextField
                        label="Date"
                        type="date"
                        variant="outlined"
                        className={classes.input}
                        value={roadmapDate}
                        onChange={(e) => setRoadmapDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleAddRoadmapItem}
                    >
                        Add Roadmap Item
                    </Button>
                </form>
            )}
            {message && <Typography variant="body1" color="error">{message}</Typography>}
        </Container>
    );
};

export default AdminPanel;
